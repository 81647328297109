import React, { Fragment, useState } from 'react';
import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';
import { Sizes } from '../../constants/Image';
import Price, { PriceType } from '../Price/Price';
import Image from '../Image';
import { gql, useMutation, useQuery } from '@apollo/client';
import Loader from '../Loader/Loader';
import useStores from '../../hooks/useStores';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import DiscountPercentage from '../Price/DiscountPercentage';

const GET_WISHLIST = gql`
  query Wishlist($uuid: ID) {
    wishlist(uuid: $uuid) {
      id
      token
      items {
        id
        quantity
        product {
          id
          title
          slug
          productCode
          defaultPrice
          salePrice
          clientSalePrice
          brand {
            id
            title
          }
          categories {
            id
            gridTitle
            slug
            title
          }
          cover {
            cdn_url
            urls {
              W100
              W800
            }
          }
          colours {
            id
            slug
            title
          }
          prices {
            value
            type
          }
          isNew
        }
        variation {
          id
          type
          value
          code
        }
      }
    }
  }
`;

type Wishlist = {
  id: string;
  token: string;
  items: WishlistItem[];
};

type WishlistItem = {
  id: string;
  quantity: number;
  product: {
    id: number;
    title: string;
    slug: string;
    productCode: string;
    defaultPrice: number;
    salePrice: number;
    clientSalePrice: number;
    brand: {
      id: number;
      title: string;
    };
    categories: {
      id: string;
      gridTitle: string;
      title: string;
      slug: string;
    }[];
    cover: {
      cdn_url: string;
      urls: {
        W100: string;
        W800: string;
      };
    };
    colours: {
      id: string;
      slug: string;
      title: string;
    }[];
    prices: {
      value: number;
      type: PriceType;
    }[];
    isNew: boolean;
  };
  variation: {
    id: number;
    type: string;
    value: string;
    code: string;
  };
};

const WISHLIST_TO_CART = gql`
  mutation WishlistToCart($wishlistToken: String!, $cartToken: String!) {
    wishlistToCart(wishlistToken: $wishlistToken, cartToken: $cartToken)
  }
`;

type Props = {
  uuid: string;
  onClose: () => void;
};

export default function OpenModalFromMail({ uuid, onClose }: Props) {
  const { cart } = useStores();
  const { loading, error, data, previousData } = useQuery<{
    wishlist: Wishlist;
  }>(GET_WISHLIST, {
    variables: { uuid: uuid },
    skip: !uuid,
  });
  const [wishlistToCart] = useMutation(WISHLIST_TO_CART);
  const [isOpen, setIsOpen] = useState(uuid ? true : false);

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="m-5">Error: {error.message}</div>;
  }

  const items = data?.wishlist.items || previousData?.wishlist.items;

  const handleWishlistToCart = async () => {
    try {
      await wishlistToCart({
        variables: {
          wishlistToken: uuid,
          cartToken: cart.token,
        },
      });
      setIsOpen(false);
      onClose();
    } catch (error) {
      console.error('Error adding items from wishlist to cart', error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-[9999]"
    >
      <TransitionChild
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-60" />
      </TransitionChild>
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center p-4 justify-center">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-sm md:max-w-screen-sm lg:max-w-screen-lg p-8">
            <DialogTitle className="mb-4 text-2xl font-semibold leading-8 text-black">
              <Translate component="wishlist" keyword="items" />
            </DialogTitle>
            <div className="absolute right-0 top-0 pr-4 pt-4 block">
              <button
                type="button"
                className="rounded-md bg-white text-black outline-none"
                onClick={() => {
                  setIsOpen(false);
                  onClose();
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6 m-2" aria-hidden="true" />
              </button>
            </div>
            <div className="mb-5 lg:mb-0">
              {items && items.length ? (
                <div className="grid gap-8 pt-2 w-full grid-cols-1 lg:grid-cols-2 mb-5">
                  {items.map((item) => (
                    <div key={item.id} className="relative flex">
                      <LocalizedLink
                        to={`/toode/${item.product.slug}`}
                        className="mr-4"
                      >
                        <Image
                          className={
                            'rounded bg-rgrey-lightlight object-cover w-32 md:w-52 h-auto'
                          }
                          defaultSize={Sizes.W800}
                          image={item.product.cover}
                          allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
                          alt={`${item.product.brand?.title} ${item.product.title}`}
                          width="100"
                          height="100"
                        />
                      </LocalizedLink>
                      <DiscountPercentage
                        prices={item.product.prices}
                        productNew={item.product.isNew}
                        componentClassname="absolute left-0 !text-xs"
                        containerClassname="items-start pt-2 pl-2 md:pt-4 md:pl-3"
                      />

                      <div className="flex flex-col pr-2 lg:pr-0">
                        <div className="w-36">
                          <p className="text-xs lg:text-sm leading-6 text-rgrey font-axi-bold">
                            {item.product.brand.title}{' '}
                            {item.product.categories[0].gridTitle}
                          </p>
                          <h3 className="text-lg lg:text-xl leading-6 font-axi-semibold">
                            {item.product.title}
                          </h3>
                        </div>
                        <div className="flex mt-1 md:mt-2">
                          <span className="flex items-center text-center text-xs leading-6 px-2.5 py-1.5 mr-2 border border-rgrey-light rounded-md font-axi-bold">
                            {item.variation.value}
                          </span>
                          <span className="flex items-center text-center text-xs leading-6 px-2.5 py-1.5 mr-2 border border-rgrey-light rounded-md font-axi-bold">
                            {item.quantity}
                          </span>
                        </div>
                        <div className="mt-1 md:mt-5">
                          <Price
                            prices={item.product.prices}
                            productNew={item.product.isNew}
                            priceLabel={true}
                            discountPriceClassname="text-base mr-1"
                            defaultPriceClassName="text-xs text-nowrap lg:text-sm"
                            discountLabelClassname="text-xs"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex justify-center py-2 w-full">
                  <p className="text-lg">
                    <Translate component="wishlist" keyword="empty" />
                  </p>
                </div>
              )}
            </div>

            <div className="flex justify-center py-2">
              {items && items.length ? (
                <LocalizedLink
                  className="border border-solid border-rgrey-light rounded transition-all duration-100 font-axi-bold mt-2.5 py-3 px-6 hover:bg-rgreen hover:text-white bg-white text-black w-full text-center"
                  to={'/checkout'}
                  onClick={handleWishlistToCart}
                >
                  <Translate component="product" keyword="add-to-cart" />
                </LocalizedLink>
              ) : (
                <LocalizedLink
                  className="border border-solid border-rgrey-light rounded transition-all duration-100 font-axi-bold mt-2.5 py-3 px-6 hover:bg-rgreen hover:text-white bg-white text-black w-full text-center"
                  to={'/'}
                >
                  <Translate component="link" keyword="back-home" />
                </LocalizedLink>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
